import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "grid grid-cols-3 items-start border-t border-gray-200" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "grid col-start-2 grid-cols-2 col-span-2 h-full" }
const _hoisted_4 = { class: "col-span-3 flex flex-col justify-center bg-gray-200" }
const _hoisted_5 = { class: "grid grid-cols-4 items-center justify-center" }
const _hoisted_6 = ["name", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.kebab(_ctx.text),
      class: "block h-full text-sm font-medium text-gray-700 p-4 sm:py-5 flex flex-col justify-center imtes-center"
    }, _toDisplayString(_ctx.text), 9, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.len, (n) => {
            return (_openBlock(), _createElementBlock("div", {
              key: n,
              class: "flex items-center justify-center"
            }, [
              _withDirectives(_createElementVNode("input", {
                name: _ctx.kebab(_ctx.text),
                type: "radio",
                value: n - 1,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
                onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
              }, null, 40, _hoisted_6), [
                [_vModelRadio, _ctx.value]
              ])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}