
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    headers: Array
  }
})
export default class ScaleHeader extends Vue {
  headers!: Array<string>
}
