
import Scale from '@/models/Scale'
import Patient from '@/models/Patient'
import ScaleHeader from '@/components/ScaleHeader.vue'
import ScaleRadio from '@/components/ScaleRadio.vue'
import { Options, Vue } from 'vue-class-component'
import { mapActions, mapGetters } from 'vuex'
import LifeStage from '@/enums/LifeStage'

@Options({
  components: {
    ScaleHeader,
    ScaleRadio
  },
  methods: mapActions(['downloadAdhdSeverityScale', 'updateAdhdSeverityScale']),
  computed: mapGetters(['patient', 'severityScale', 'lifeStage'])
})
export default class PatientData extends Vue {
  severityScale!: Scale
  patient!: Patient
  lifeStage!: LifeStage
  headers: Array<string> = []

  // Actions
  updateAdhdSeverityScale!: (payload: {
    id: number
    severityScale: Scale
  }) => Promise<void>

  downloadAdhdSeverityScale!: () => Promise<void>

  async created (): Promise<void> {
    await this.downloadAdhdSeverityScale()

    this.headers = [
      this.$t('AdHdSeverityScale.HeaderNever'),
      this.$t('AdHdSeverityScale.HeaderSometimes'),
      this.$t('AdHdSeverityScale.HeaderOften'),
      this.$t('AdHdSeverityScale.HeaderVeryOften')
    ]
  }

  async submit (): Promise<void> {
    try {
      await this.updateAdhdSeverityScale({
        id: this.patient.id,
        severityScale: this.severityScale
      })
      this.$router.back()
      // this.$router.push({
      //   name: 'PatientData',
      //   params: { id: this.patient.id }
      // })
    } catch {
      this.$router.push({ name: 'Logout' })
    }
  }
}
