
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    len: {
      type: Number,
      default: 4
    },
    text: String,
    modelValue: Number
  }
})
export default class ScaleRadio extends Vue {
  len!: number
  text!: string
  modelValue!: 0
  value!: 0

  kebab (text: string): string {
    return text
      .replace(/([àáèéìíòóùú])/g, '')
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/[\s_]+/g, '-')
      .toLowerCase()
  }

  created (): void {
    this.value = this.modelValue
  }

  onInput (event: InputEvent): void {
    this.$emit(
      'update:modelValue',
      +(event?.target as HTMLTextAreaElement).value
    )
  }
}
