import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "grid grid-cols-3 items-start border-t border-gray-200" }
const _hoisted_2 = { class: "grid col-start-2 grid-cols-2 col-span-2 h-full" }
const _hoisted_3 = { class: "col-span-3 flex flex-col justify-center bg-gray-200" }
const _hoisted_4 = { class: "grid grid-cols-4 items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
            return (_openBlock(), _createElementBlock("span", {
              key: header,
              class: "justify-center inline-flex px-4 py-2 border-b border-gray-300 bg-white text-sm font-medium text-gray-700"
            }, _toDisplayString(header), 1))
          }), 128))
        ])
      ])
    ])
  ]))
}